<style scoped lang="scss">
  .keeping_list_box {
    .toolbars {
      .addbtn {
        float: left;
      }
      .otherbar {
        float: right;
        .searchtime {
          margin-left: 10px;
        }
        .selectservicer,.searchbtn  {
          display: inline-block;
        }
      }
    }
  }
</style>

<template>
  <div class="keeping_list_box">
    <div class="toolbars mrgb5">
      <div class="otherbar">
        <date-time @commitPicker="getPicker" class="mrgr5 mrgb5"></date-time>
        <el-button class="searchbtn mrgb5" type="primary" plain size="small" @click="_search"><i class="fa fa-search"></i> 查询</el-button>
      </div>
    </div>

    <el-table :data="tableData" show-summary
      style="width: 100%" border size="mini">
      <el-table-column label="日期" prop="time"></el-table-column>
      <el-table-column label="次日留存" prop="s1"></el-table-column>
      <el-table-column label="3日留存" prop="s2"></el-table-column>
      <el-table-column label="5日留存" prop="s3"></el-table-column>
      <el-table-column label="7日留存" prop="s4"></el-table-column>
      <el-table-column label="10日留存" prop="s5"></el-table-column>
      <el-table-column label="15日留存" prop="s6"></el-table-column>
      <el-table-column label="20日留存" prop="s7"></el-table-column>
      <el-table-column label="30日留存" prop="s8"></el-table-column>
      <el-table-column label="60日留存" prop="s9"></el-table-column>
      <el-table-column label="90日留存" prop="s10"></el-table-column>
    </el-table>
  </div>
</template>

<script>
  import dateTime from "@/components/DateTime"
  export default {
    name: "keeping",
    components: {
      dateTime
    },
    data() {
      return {
        tableData: [],
        listQuery: {
          serverId: null,
          beginTime: null,
          endTime: null,
          status: null,
          page: 1,
          size: window.$common.appPageSize
        }
      }
    },
    mounted() {
      this.getDataList()
    },
    methods: {
      getDataList() {
        this.tableData = [
          { time: "2019-09-20", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-19", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-18", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-17", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-16", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-15", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-14", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-13", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-12", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 },
          { time: "2019-09-11", s1: 10, s2: 20, s3: 30, s4: 40, s5: 50, s6: 60, s7: 70, s8: 80, s9: 90, s10: 100 }
        ]
      },
      getPicker(val) {
        this.listQuery.beginTime = val[0]
        this.listQuery.endTime = val[1]
      },
      commonSuccess(val) {
        let tips = val || "操作成功!"
        this.alert(tips).then(() => {
          window.$common.closeFullLoading()
        })
      },
      _search() {
        
      }
    }
  }
</script>